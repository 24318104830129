import avatars from '../../img/avatars';
import { BridalPartyMember, Affiliation, Role } from "./BridalPartyMember";

export const people = [
    {
        name: 'Alison',
        photo: avatars.Alison,
        prompt: 'Will\'s green flags...',
        response: 'The fact that Meeko has accepted him. I\'ve been working on it for years and still haven\'t made it into his inner circle!',
        affiliation: Affiliation.Bridesperson,
        location: 'Chicago',
        context: 'College',
    },
    {
        name: 'Brandi',
        photo: avatars.Brandi,
        prompt: 'I\'ll do a shot at the wedding if you...',
        response: 'Make it Fireball!',
        affiliation: Affiliation.Groomsperson,
        location: 'Chicago',
        context: 'Chicago'
    },
    {
        name: 'Carl',
        photo: avatars.Carl,
        prompt: 'How did you meet Lord William...',
        response: 'I met him in high school, we had a bromance in the cafeteria. Oh those awkward high school years, what fun.',
        affiliation: Affiliation.Groomsperson,
        location: 'Iowa',
        context: 'High School'
    },
    {
        name: 'Chris',
        photo: avatars.Chris,
        prompt: 'If Will and Cailin were a superhero couple they\'d be...',
        response: 'If they were...? What is this "if?"',
        affiliation: Affiliation.Groomsperson,
        role: Role.BestMan,
        location: 'Iowa',
        context: 'At my birth'
    },
    {
        name: 'Gillian',
        photo: avatars.Gillian,
        prompt: 'How you\'ll recognize me at the wedding...',
        response: 'I\'ll be the one shaking like a leaf with Tide sticks falling out of my pockets!',
        affiliation: Affiliation.Bridesperson,
        role: Role.MaidOfHonor,
        location: 'Illinois',
        context: 'At her birth'
    },
    {
        name: 'Kevin',
        photo: avatars.Kevin,
        prompt: 'I recently discovered that...',
        response: 'Will and Cailin are secretly in love. 🤫',
        affiliation: Affiliation.Groomsperson,
        role: Role.BestMan,
        location: 'Wisconsin',
        context: 'Brother from the same mother'
    },
    {
        name: 'Jerry',
        photo: avatars.Jerry,
        prompt: 'It\'ll be a great wedding if...',
        response: 'Somehow there is a karaoke session during the reception (wishful thinking).',
        affiliation: Affiliation.Groomsperson,
        location: 'Iowa',
        context: 'College'
    },
    {
        name: 'Meeko',
        photo: avatars.Meeko,
        prompt: 'I\'m convinced that the couple...',
        response: 'Never feeds me.',
        affiliation: Affiliation.Us,
        location: 'Chicago',
        context: 'Adopted'
    },
    {
        name: 'Michael',
        photo: avatars.Michael,
        prompt: 'How you\'ll recognize me at the wedding...',
        response: 'I\'ll be on the dance floor, screaming and jumping the most out of anyone.',
        affiliation: Affiliation.Bridesperson,
        role: Role.ManOfHonor,
        location: 'Florida',
        context: 'College'
    },
    {
        name: 'Michelle',
        photo: avatars.Michelle,
        prompt: 'If I could bring a special guest to the wedding, it\'d be...',
        response: 'Mr. 305. Or maybe Fletcher, just for Cailin.',
        affiliation: Affiliation.Bridesperson,
        location: 'Chicago',
        context: 'Chicago'
    },
    {
        name: 'Monika',
        photo: avatars.Monika,
        prompt: 'I\'ll do a shot at the wedding if you...',
        response: 'Chase it with a shot of pickle juice.',
        affiliation: Affiliation.Bridesperson,
        location: 'Chicago',
        context: 'Chicago'
    },
    {
        name: 'Nealand',
        photo: avatars.Nealand,
        prompt: 'Cailin\'s green flag...',
        response: 'Cailin did not blink twice or give me any other signs when I asked her if Will was holding her hostage the first time I met her.',
        affiliation: Affiliation.Groomsperson,
        location: 'Kansas',
        context: 'College'
    },
    {
        name: 'Sally',
        photo: avatars.Sally,
        prompt: 'It\'ll be a great wedding if...',
        response: 'The DJ can successfully combine Cailin and Will\'s music tastes. ',
        affiliation: Affiliation.Bridesperson,
        location: 'Michigan',
        context: 'College'
    },
    {
        name: 'Scott',
        photo: avatars.Scotty,
        prompt: 'If I could bring a special guest to the wedding, it\'d be...',
        response: 'Vin Diesel. Cause there\'s always room for family...',
        affiliation: Affiliation.Groomsperson,
        location: 'Iowa',
        context: 'Junior High'
    },
    {
        name: 'Sharon',
        photo: avatars.Sharon,
        prompt: 'Will\'s green flag...',
        response: 'Will has embraced Cailin\'s hosting of weekly bachelor watch parties. With his adds of literal red flags for us to wave and a website to track the roses, I can\'t imagine bach nights without him!',
        affiliation: Affiliation.Bridesperson,
        location: 'Chicago',
        context: 'Chicago'
    }
] as BridalPartyMember[];