import React, { useEffect } from 'react';
import styled, { createGlobalStyle} from 'styled-components';

import { Blur } from './Blur';
import { Header } from '../../Components/Header';
import { Footer } from '../../Components/Footer';
import { PeopleContainer } from './PeopleContainer';
import { Pills, Section } from 'src/Components/Pills';

import moodyW from '../../img/moody.webp';
import moodyJ from '../../img/moody.jpg';
import { Us } from './Us';
import { SectionHeader } from '../../Components/SectionHeader';
import { Person } from './Person';
import { Affiliation } from './BridalPartyMember';
import { people } from './people';

const FloralBackground = createGlobalStyle`
    body {
        background-image: url(${moodyW}),
        url(${moodyJ});
        background-size: cover;
    }
`;

const Main = styled.main`
    padding: 0 0 2rem 0;
`;

const sections = [
    new Section('us', "Us"),
    new Section('herpeople', "Her People"),
    new Section('hispeople', "His People"),
    new Section('ourpeople', "Our People")
];

export const Story = () => {
    const showGooglyEyes = document.cookie.indexOf('goog=true') > -1;

    useEffect(() => {
        const moveEyes = (e: MouseEventInit) : void => {
            const eyes = document.querySelectorAll('.eyeball');

            for (let i = 0, len = eyes.length; i < len; i++) {
                const eyeball = eyes[i],
                    pupil = eyeball.nextElementSibling,
                    svg = eyeball.closest('svg');

                if (!pupil) {
                    continue;
                }

                // get center cx/cy and radius
                let pCenter = { x: +(eyeball.getAttribute('cx')!), y: +(eyeball.getAttribute('cy')!) };
                let rEyeball = +(eyeball!.getAttribute('r')!);
                let rPupil = +(pupil!.getAttribute('r')!);

                // translate cursor HTML DOM coordinates to SVG DOM units
                let pCursor = new DOMPoint(e.clientX, e.clientY);
                pCursor = pCursor.matrixTransform(svg!.getScreenCTM()!.inverse());

                // get angle between cursor and eyeball center;
                let angle = (Math.atan2(pCursor.y - pCenter.y, pCursor.x - pCenter.x) * 180) / Math.PI;

                //get distance between cursor and eyeball center
                let a = pCursor.x - pCenter.x;
                let b = pCursor.y - pCenter.y;
                let distance = Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));

                // adjust pupil movement inside eyeball boundaries
                let offset = distance < rEyeball ? 1 / rEyeball * distance : 1;
                let radiusOuter = (rEyeball - rPupil) * offset;

                let pMoved = {
                    x: pCenter.x + Math.cos((angle * Math.PI) / 180) * radiusOuter,
                    y: pCenter.y + Math.sin((angle * Math.PI) / 180) * radiusOuter
                };
                // update attributes
                pupil.setAttribute('cx', pMoved.x.toString());
                pupil.setAttribute('cy', pMoved.y.toString());
            }
        };

        if (showGooglyEyes) {
            document.addEventListener('mousemove', moveEyes);
        }

        return () => document.removeEventListener('mousemove', moveEyes);
    }, [showGooglyEyes]);

    return (
        <>
            <FloralBackground />
            <Blur />

            <Header />

            <Main>
                <Pills sections={sections} />

                <PeopleContainer id="us">
                    <Us />
                </PeopleContainer>

                <PeopleContainer id="herpeople">
                    <SectionHeader>Her People</SectionHeader>

                    {
                        people.filter(p => p.affiliation === Affiliation.Bridesperson).map(p => (
                            <Person key={p.name} person={p} />
                        ))
                    }
                </PeopleContainer>

                <PeopleContainer id="hispeople">
                    <SectionHeader>His People</SectionHeader>

                    {
                        people.filter(p => p.affiliation === Affiliation.Groomsperson).map(p => (
                            <Person key={p.name} person={p} />
                        ))
                    }
                </PeopleContainer>

                <PeopleContainer id="ourpeople">
                    <SectionHeader>Our People</SectionHeader>

                    {
                        people.filter(p => p.affiliation === Affiliation.Us).map(p => (
                            <Person key={p.name} person={p} />
                        ))
                    }
                </PeopleContainer>
            </Main>

            <Footer />
        </>
    );
};