import styled from 'styled-components';

import { device } from '../../Constants/MediaQueries';

export const CardPhoto = styled.div`
    display: grid;
    justify-content: center;
    justify-self: flex-start;
    height: 200px;
    margin-left: -4rem;
    overflow: hidden;

    &:active,
    &:hover,
    &:focus {
        animation: pulse 10s ease-in-out infinite;
    }

    & img {
        height: 200px;
    }

    @media ${device.laptop} {
        animation: none;
        justify-self: center;
        margin-left: initial;
        margin-bottom: 2rem;

        &.do-pulse {
            animation: pulse 10s ease-in-out infinite;
        }
    }
`;