import styled from 'styled-components';
import { Card } from '../../Components/Cards/Card';
import { device } from '../../Constants/MediaQueries';

export const PersonCard = styled(Card)`
    align-items: start;
    gap: 1rem;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr;
    justify-content: center;
    margin: initial;
    padding: 1.5rem;
    padding-bottom: 3.5rem;
    width: 40vw;

    @media ${device.tablet} {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto 280px 4.5rem;
        padding: 1.5rem;

        max-width: 95vw;
        width: 95vw;
    }
`;
