import React from 'react';

import { Icon } from '../../Components/Icon';
import { GridCard } from './GridCard';
import { CardPhoto } from './CardPhoto';
import { MobileCenteredCardDetails } from './CardDetails';

import SuitUp from '../../img/suit-up.gif';

export const DressCode = () => (
    <GridCard>
        <CardPhoto>
            <img src={SuitUp} alt="Time to suit up meme from who's your mother" />
        </CardPhoto>

        <MobileCenteredCardDetails>
            <ul>
                <li>
                    <h2>Rehearsal Dinner + Welcome Drinks: <a target="_blank" data-hook="explain-dress-code" href="https://www.masterclass.com/articles/how-to-put-together-a-dressy-casual-outfit">Dressy Casual</a></h2>
                </li>
                <li>
                    <h2>Ceremony + Reception: <a target="_blank" data-hook="explain-dress-code" href="https://www.theknot.com/content/wedding-guest-attire-cheat-sheet#formal-attire">Formal</a></h2>
                </li>
                <li>
                    <h2>Brunch: Casual</h2>
                </li>
            </ul>
        </MobileCenteredCardDetails>
    </GridCard>
);
