import React from 'react';
import styled from 'styled-components';

import { GridCard } from './GridCard';
import { CardText } from './CardText';
import { device } from '../../Constants/MediaQueries';
import { ButtonLink } from 'src/Components/ButtonLink';
import { CenteredButtonContainer } from './ButtonContainer';

const CountdownText = styled.div`
    align-items: center;
    color: var(--header-color);
    display: grid;
    font-family: var(--header-font);
    font-size: 2rem;
    font-weight: 100;
    grid-auto-flow: row;
    justify-content: center;
    

    & b {
        font-size: 6rem;
        font-weight: 400;
        line-height: 100%;
    }

    & * {
        text-align: center;
    }

    @media ${device.laptop} {
        font-size: 2rem;
        text-align: center;

        & b {
            display: block;
            font-size: 4rem;
        }
    }
`;

const CardDetail = styled.div`
    text-align: center;
    
    & > * + * {
        margin-top: 0.5rem;
    }
`;

const BigDate = styled.h1`
    font-size: 4rem;
    font-weight: 300;
    line-height: 120%;

    @media ${device.laptop} {
        margin-bottom: 2rem;
    }
`;

export const CountdownCard = () => {
    const today = new Date();
    const target = new Date('2023-11-04T22:00:00Z');

    const days = parseInt(((target.getTime() - today.getTime()) / (1000 * 60 * 60 *24)).toString(), 10);

    const countdownText = days > 1 ? 'days to go' : days === 1 ? 'day to go' : 'We\'re Married!'

    return (
        <GridCard>
            <CardText>
                <CountdownText>
                    { days > 0 ? (<b>{days}</b>) : null }
                    <span>{countdownText}</span>
                </CountdownText>
            </CardText>

            <CardDetail>
                <BigDate>November 4th, 2023</BigDate>

                <CenteredButtonContainer>
                    <ButtonLink data-hook="add-to-calendar" target="_blank" href="https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20231104%2F20231105&location=Greenhouse%20Loft&text=Cailin%20%26%20Will%27s%20Wedding">Add to Google Calendar</ButtonLink>
                    <ButtonLink data-hook="download-appointment" href="data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0AVERSION:2.0%0ABEGIN:VEVENT%0ADTSTART:20231104%0ADTEND:20231105%0ASUMMARY:Cailin%20%26%20Will's%20Wedding%0ALOCATION:Greenhouse%20Loft%0AEND:VEVENT%0AEND:VCALENDAR%0A">Download Appointment</ButtonLink>
                </CenteredButtonContainer>

                <p>
                    Be sure to checkout our wedding hashtag of <a target="_blank" data-hook="view-hashtag" href="https://www.instagram.com/explore/tags/cailingotdibbs">#cailingotdibbs</a>
                </p>
            </CardDetail>
        </GridCard>
    );
}