import styled from 'styled-components';
import { device } from '../../Constants/MediaQueries';

export const PromptContainer = styled.div`
    display: block;
    grid-column: 1;
    grid-row: 2;

    & :first-child {
        font-size: 1.25rem;
        font-weight: 100;
        margin-bottom: 0.5rem;
    }

    & :last-child {
        font-size: 1.8rem;
        font-weight: 300;
        line-height: 120%;
    }

    @media ${device.laptop} {
        grid-row: 2;
    }
`;
