import React, { useEffect } from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { Page } from '../Constants/Page';

import { validate } from '../Logic/Validator';

interface ProtectedRouteProps {
    redirectPath?: string,
    children: JSX.Element | null
}

export const ProtectedRoute = ({
    redirectPath = '/',
    children
}: ProtectedRouteProps): JSX.Element | null => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (document.cookie.indexOf('enter=true') < 0) {
            const passphrase = searchParams.has('p') ?
                searchParams.get('p') : null;
    
            if (passphrase && validate(passphrase)) {
                searchParams.delete('p');
                setSearchParams(searchParams, { replace: true });
            }
            else if (location.pathname !== '/') {
                return navigate(redirectPath, { replace: true });
            }
        }
        else if (location.pathname === '/') {
            navigate(`/${Page.Story}`, { replace: true });
        }
    }, [navigate, redirectPath, searchParams, setSearchParams, location]);

    return children;
};