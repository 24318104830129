import React from 'react';
import styled from 'styled-components';

import { device } from '../../Constants/MediaQueries';

export const CardDetails = styled.div`
    display: grid;
    grid-template-columns: initial;
    grid-auto-rows: auto;
    row-gap: 1.5rem;

    & > * + * {
        margin-top: 1rem;
    }
`;

export const CenteredCardDetails = styled(CardDetails)`
    text-align: center;
`;

export const MobileCenteredCardDetails = styled(CardDetails)`
    row-gap: 0.5rem;
    
    @media ${device.laptop} {
        text-align: center;
    }
`;