import React from 'react';

import { ButtonLink } from '../../Components/ButtonLink';
import { Card } from '../../Components/Cards/Card';
import { MobileCenteredButtonContainer } from './ButtonContainer';
import { CardHeader } from './CardHeader';
import { MobileCenteredCardDetails } from './CardDetails';

export const HotelCard = () => (
    <Card>
        <CardHeader>
            <h1>Joining us from out of town?</h1>

            <p>Please consider booking early, you can always change the reservation later if needed. In between both of these lovely hotels is the Starbucks Reserve Roastery ☕️, a mere two minute walk away...</p>

            <p><b>Our preferred hotel rates expire 10/13/2023!</b></p>
        </CardHeader>

        <MobileCenteredCardDetails>
            <div>
                <h1><a target="_blank" data-hook="view-map-hyatt" href="https://www.hyatt.com/en-US/hotel/illinois/hyatt-centric-chicago-magnificent-mile/chimm">Hyatt Centric Chicago Mag Mile</a></h1>
                <p>This hotel is where the Bride and Groom will be getting ready!</p>
                <MobileCenteredButtonContainer>
                    <ButtonLink data-hook="book-hyatt" target="_blank" href="https://www.hyatt.com/en-US/group-booking/CHIMM/G-ODWB">Book It</ButtonLink>
                    <ButtonLink data-hook="directions-hyatt" target="_blank" href="https://www.google.com/maps/search/?api=1&query=Hyatt+Centric+Chicago+Magnificent+Mile">Get Directions</ButtonLink>
                    <ButtonLink data-hook="request-uber-hyatt" target="_blank" href="https://m.uber.com/ul/?action=setPickup&client_id=R-xt0O21bXspR381CjkUzR099lQckkik&pickup=my_location&dropoff[formatted_address]=Hyatt%20Centric%20Chicago%20Magnificent%20Mile%2C%20North%20Saint%20Clair%20Street%2C%20Chicago%2C%20IL%2C%20USA&dropoff[latitude]=41.893837&dropoff[longitude]=-87.622375">Request an Uber</ButtonLink>
                </MobileCenteredButtonContainer>
            </div>

            <div>
                <h1><a target="_blank" data-hook="view-map-museum" href="https://www.21cmuseumhotels.com/chicago/">21c Museum Hotel Chicago</a></h1>
                <p>This hotel is walking distance from the Hyatt, and has a lovely little museum in it!</p>
                <MobileCenteredButtonContainer>
                    <ButtonLink data-hook="book-museum" target="_blank" href="https://book.passkey.com/e/50450525">Book It</ButtonLink>
                    <ButtonLink data-hook="directions-museum" target="_blank" href="https://www.google.com/maps/search/?api=1&query=21c+Museum+Hotel+Chicago">Get Directions</ButtonLink>
                    <ButtonLink data-hook="request-uber-hyatt" target="_blank" href="https://m.uber.com/ul/?action=setPickup&client_id=R-xt0O21bXspR381CjkUzR099lQckkik&pickup=my_location&dropoff[formatted_address]=21c%20Museum%20Hotel%20Chicago%2C%20East%20Ontario%20Street%2C%20Chicago%2C%20IL%2C%20USA&dropoff[latitude]=41.893133&dropoff[longitude]=-87.625771">Request an Uber</ButtonLink>
                </MobileCenteredButtonContainer>
            </div>
        </MobileCenteredCardDetails>
    </Card>
);