import styled from 'styled-components';

export const ButtonLink = styled.a`
    background: transparent;
    border: 2px solid var(--accent-color);
    color: var(--accent-color);
    display: inline-block;
    padding: 0.5rem 1rem;
    text-decoration: none;
    transition: all 0.3s ease-in-out;

    &:visited {
        background: transparent;
        color: var(--accent-color);
    }

    &:active,
    &:focus,
    &:hover {
        background: var(--accent-color);
        color: var(--background-color);
        text-decoration: none;
    }
`;