import styled from 'styled-components';

import { device } from '../../Constants/MediaQueries';

export const ButtonContainer = styled.div`
    align-items: center;
    column-gap: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    row-gap: 1rem;

    @media ${device.laptop} {
        margin-bottom: 2rem;
    }
`;

export const CenteredButtonContainer = styled(ButtonContainer)`
    justify-content: center;
`;

export const MobileCenteredButtonContainer = styled(ButtonContainer)`
    @media ${device.laptop} {
        justify-content: center;
    }
`;
