import React from 'react';
import styled from 'styled-components';

import { Card } from '../../Components/Cards/Card';
import { CenteredCardDetails } from './CardDetails';

export const TimelineHeader = styled.header`
    margin: auto;
    text-align: center;
    
    & h1 {
        border-bottom: 1px solid var(--accent-color);
        margin-bottom: 0.5rem;
        margin: 0 1.5rem 0.5rem 1.5rem;
        padding-bottom: 0.5rem;
    }
`;

const BigText = styled.span`
    font-size: 1.25rem;
`;

export const TimelineCard = () => (
    <Card>
        <CenteredCardDetails>
            <div>
                <TimelineHeader>
                    <h1>Friday, November 3rd, 2023</h1>
                </TimelineHeader>
                <p>
                    <b>5:30 PM</b>
                    <br />
                    <BigText>Rehearsal Dinner</BigText>
                    <br />
                    <span>Dress Code: <a target="_blank" data-hook="explain-dress-code" href="https://www.masterclass.com/articles/how-to-put-together-a-dressy-casual-outfit">Dressy Casual</a></span>
                    <br />
                    <i>Wedding party only</i>
                </p>
                <p>
                    <b>8:00 PM - 11:00 PM</b>
                    <br />
                    <BigText>Welcome Drinks @ <a href="https://www.google.com/maps/place/Corcoran's+Grill+%26+Pub/@41.9116134,-87.6344858,15z/data=!4m2!3m1!1s0x0:0x650bfde0cfae9dbd?sa=X&ved=2ahUKEwjQj8Skq-SBAxV5g4kEHYKRB9kQ_BJ6BAhSEAA&ved=2ahUKEwjQj8Skq-SBAxV5g4kEHYKRB9kQ_BJ6BAhvEAg">Corcoran's Grill & Pub</a> </BigText>
                    <br />
                    <span>Dress Code: <a target="_blank" data-hook="explain-dress-code" href="https://www.masterclass.com/articles/how-to-put-together-a-dressy-casual-outfit">Dressy Casual</a></span>
                    <br />
                    <i>All welcome</i>
                </p>
            </div>

            <div>
                <TimelineHeader>
                    <h1>Saturday, November 4th, 2023</h1>
                </TimelineHeader>
                <p>
                    <b>4:30 PM</b>
                    <br />
                    <BigText>Ceremony @ <a href="#venue">Greenhouse Loft</a></BigText>
                    <br />
                    <span>Dress Code: <a target="_blank" data-hook="explain-dress-code" href="https://www.theknot.com/content/wedding-guest-attire-cheat-sheet#formal-attire">Formal</a></span>
                    <br />
                    <i>Doors open at 4:00 PM. Reception to follow, also at Greenhouse Loft</i>
                </p>
            </div>

            <div>
                <TimelineHeader>
                    <h1>Sunday, November 5th, 2023</h1>
                </TimelineHeader>
                <p>
                    <b>2:00 AM</b>
                    <br />
                    <BigText>Daylight Savings Ends</BigText>
                    <br />
                    <i>Enjoy the extra hour of sleep</i>
                </p>
                <p>
                    <b>10:30 AM - 2:30 PM</b>
                    <br />
                    <BigText>Brunch @ <a href="https://www.google.com/maps/place/Park+%26+Field/@41.9242299,-87.7142944,15z/data=!4m2!3m1!1s0x0:0x30f8866c2b438bfa?sa=X&ved=2ahUKEwjlgcaqoIKAAxXXlokEHRlaCaAQ_BJ6BAhKEAA&ved=2ahUKEwjlgcaqoIKAAxXXlokEHRlaCaAQ_BJ6BAhlEAg">Park and Field</a></BigText>
                    <br />
                    <span>Dress Code: Casual</span>
                    <br />
                    <i>All welcome</i>
                </p>
            </div>
        </CenteredCardDetails>
    </Card>
);