import styled from 'styled-components';

import { Card } from '../../Components/Cards/Card';
import { device } from '../../Constants/MediaQueries';

export const GridCard = styled(Card)`
    align-items: center;
    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: center;
    column-gap: 2rem;

    @media ${device.laptop} {
        grid-template-columns: initial;
        grid-auto-rows: auto;
    }
`;