import styled from 'styled-components';
import { device } from '../../Constants/MediaQueries';

export const AttributeIcon = styled.span`
    align-items: center;
    display: inline-flex;
    height: 1.5rem;
    justify-content: center;
    overflow: hidden;
    margin-right: 0.5rem;
    width: 1.5rem;

    & > * {
        height: 1.5rem;
        filter: invert(58%) sepia(8%) saturate(744%) hue-rotate(344deg) brightness(95%) contrast(85%);
        width: 1.5rem;
    }

    @media ${device.laptop} {
        height: 1.25rem;
        margin-right: 0.5rem;
        width: 1.25rem;

        & > * {
            height: 1.25rem;
            width: 1.25rem;
        }
    }
`;
