import styled from 'styled-components';

import { HomeForm } from './HomeForm';

export const Meeko = styled.img`
    position: absolute;
    left: 0.5rem;
    height: 1.5rem;
    opacity: 0;
    transition: all 1s ease-in-out;
    top: 0.25rem;
    z-index: 1010;

    ${HomeForm}:hover & {
        opacity: 1;
    }
`;