export const validate = (str: String ) => {
    const encodedStr = btoa(str.toLowerCase());

    if (['TWVla28=', 'bWVla28='].indexOf(encodedStr) > -1) {
        document.cookie = 'enter=true'
        return true;
    }
    else if ('Z29vZw==' === encodedStr) {
        document.cookie = 'enter=true&goog=true';
        return true;
    }

    return false;
};