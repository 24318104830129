import styled from 'styled-components';

import { device } from 'src/Constants/MediaQueries';

export const HomeHero = styled.div`
    align-items: center;
    border: 1px solid var(--card-background-color);
    color: var(--card-background-color);
    display: flex;
    flex-direction: column;
    font-size: 4rem;
    font-weight: bold;
    height: 50vh;
    margin: auto auto;
    opacity: 0.8;
    overflow: hidden;
    padding: 0 0.75em 2rem 0.75em;
    position: relative;
    z-index: 1000;

    @media ${device.tablet} {
        height: 60vh;
    }
`;