import styled from 'styled-components';

export const Title = styled.div`
    color: var(--header-color);
    display: flex;
    line-height: 1em;
    font-family: 'British Shorthair', 'Courier New', Courier, monospace;
    font-size: 3rem;
    letter-spacing: var(--uppercase-letter-spacing);
    margin: 0 0.8rem;
    text-transform: none;
    z-index: 1;

    & > * {
        padding: 0 1rem;
    }  
`;