import styled from 'styled-components';

export const HomeForm = styled.form`
    align-items: center;
    bottom: 0;
    display: flex;
    font-size: 2rem;
    font-weight: 100;
    position: absolute;
    width: 100%;  
`;