import React from 'react';
import styled from 'styled-components';

import { GridCard } from './GridCard';
import faqItems from './faq.json';
import { FAQItem } from './FAQItem';

const LeftGridCard = styled(GridCard)`
    align-items: flex-start !important;
    display: block;
`;

export const FAQCard = () => (
    <LeftGridCard>
        <ol>
            {
                faqItems.map(faqItem => <FAQItem item={faqItem} />)
            }
        </ol>
    </LeftGridCard>
);