import styled from 'styled-components';

import webp from '../../img/CW-04.webp';
import jpg from '../../img/CW-04.jpg';

export const HomeContainer = styled.main`
    background-image: url(${webp}), url(${jpg});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    font-family: "Asar";
    height: 100vh;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100vw;
`;