import React from 'react';
import { useLocation } from 'react-router-dom';

import { Page } from '../../Constants/Page';
import { BottomNav } from './BottomNav';
import { TopNav } from './TopNav';
import { PageHeader } from './PageHeader';
import { Title } from './Title';
import { NavContainer } from './NavContainer';
import { NavItem } from './NavItem';
import { ScrollToTop } from '../ScrollToTop';
import { Fathom } from '../Fathom';

const ROUTE_MAP = [
    { displayName: 'Our Story', url: `/${Page.Story}` },
    { displayName: 'Details', url: `/${Page.Details}` },
    { displayName: 'Registry', url: 'https://withjoy.com/will-and-cailin/registry', target: '_blank' },
    { displayName: 'RSVP', url: `/${Page.RSVP}` }
];

export const Header = () => {
    const loc = useLocation();

    return (
        <PageHeader>
            <ScrollToTop />
            <Fathom />
            <NavContainer>
                <TopNav>
                    {
                        ROUTE_MAP.slice(0, 2).map(route => (
                            <NavItem key={route.displayName} isActive={route.url === loc.pathname} destination={route.url} target={route.target}>{route.displayName}</NavItem>
                        ))
                    }
                </TopNav>

                <Title>Cailin  + Will</Title>

                <TopNav>
                    {
                        ROUTE_MAP.slice(2).map(route => (
                            <NavItem key={route.displayName} isActive={route.url === loc.pathname} destination={route.url} target={route.target}>{route.displayName}</NavItem>
                        ))
                    }
                </TopNav>

                <BottomNav>
                    {
                        ROUTE_MAP.map(route => (
                            <NavItem key={route.displayName} isActive={route.url === loc.pathname} destination={route.url} target={route.target}>{route.displayName}</NavItem>
                        ))
                    }
                </BottomNav>
            </NavContainer>
        </PageHeader>
    );
};