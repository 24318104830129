import { useEffect } from 'react';
import { trackPageview } from 'fathom-client';
import { useLocation } from 'react-router-dom';

export const Fathom = () => {
    const location = useLocation();

    useEffect(() => {
        trackPageview();
    }, [location]);

    return null;
}