import styled from 'styled-components';

import { device } from '../../Constants/MediaQueries';

export const CardText = styled.div`
    align-items: center;
    background: var(--card-background-color);
    border: 2px solid var(--primary-color);
    display: grid;
    justify-content: center;
    height: 200px;
    margin-left: -4rem;
    overflow: hidden;
    width: 200px;

    @media ${device.laptop} {
        justify-self: center;
        margin-left: initial;
        margin-bottom: 2rem;
    }
`;