import React, { useEffect } from 'react';
import {
    createBrowserRouter,
    RouterProvider,
} from 'react-router-dom';
import { load } from 'fathom-client';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';

import { Page } from './Constants/Page';
import { Home } from './Pages/Home';
import { Story } from './Pages/Story';
import { Details } from './Pages/Details';
import { ProtectedRoute } from './Components/ProtectedRoute';
import { RSVP } from './Pages/RSVP';

const router = createBrowserRouter([
    {
        path: '/',
        element: <ProtectedRoute><Home /></ProtectedRoute>,
    },
    {
        path: `/${Page.Story}`,
        element: <ProtectedRoute><Story /></ProtectedRoute>,
    },
    {
        path: `/${Page.Details}`,
        element: <ProtectedRoute><Details /></ProtectedRoute>,
    },
    {
        path: `/${Page.RSVP}`,
        element: <ProtectedRoute><RSVP /></ProtectedRoute>,
    },
    // {
    //     path: `/${Page.WeddingPartyInfo}`,
    //     element: <Party />,
    // },
    {
        path: '*',
        element: (<h1>Did you get lost? Please contact the bride or the groom if you can't find what you're looking for.</h1>)
    }
]);

const envKey = /(localhost)|(test)/i.test(document.location.href) ?
    'QzwirgvmejwCKjwTirWwgB' : 'cmTE8PFdEDzwkirgKMTip5';

export const App = () => {
    useEffect(() => {
        load('YRXXUYQS');
    }, []);

    return (
        <FlagsmithProvider options={{
            environmentID: envKey,
            defaultFlags: {
                rsvp: { enabled: false },
            },
            
            cacheFlags: true
        }} flagsmith={flagsmith}>
            <RouterProvider router={router} />
        </FlagsmithProvider>
    );
}