import styled from 'styled-components';

export const AttributesContainer = styled.div`
    background-color: var(--card-background-color-light);
    position: absolute;
    bottom: 0;
    left: 0;
    overflow: hidden;
    right: 0;
    width: 100%;
    z-index: 1000;
`;
