import React from 'react';

import { CardDetails } from './CardDetails';
import { GridCard } from './GridCard';
import { CardPhoto } from './CardPhoto';

import CrowdPhoto from '../../img/crowd.jpg';

export const PhotoCard = () => (
    <GridCard>
        <CardPhoto>
            <img src={CrowdPhoto} alt="Photo of crowd with cellphones" />
        </CardPhoto>

        <CardDetails>
            <h1>A note on photos</h1>

            <p>
                We'd appreciate it if everyone kept their phones in their pockets for our ceremony, but after that, please take all the photos you want!
                <br />
                <br />
                We've setup <a href="https://my.guestpix.com/Guest/Access/27735/IGqw2RvX">this shared album here</a> to collect photos from everyone. Please do upload your favorites from the day so we see our day from your perspective!
            </p>
        </CardDetails>
    </GridCard>
);