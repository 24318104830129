import styled from 'styled-components';

import { device } from '../../Constants/MediaQueries';

export const Card = styled.article`
    background: var(--card-background-color);
    margin: auto auto;
    padding: 1.5rem;
    position: relative;
    width: 50vw;

    @media ${device.tablet} {
        max-width: 95vw;
        width: 95vw;
    }
`;