import React from 'react';
import styled from 'styled-components';

import { Card } from '../../Components/Cards/Card';
import { CardHeader } from './CardHeader';
import { CardDetails } from './CardDetails';

export const TransportationCard = () => (
    <Card>
        <CardHeader>
            <h1>Getting Around</h1>
        </CardHeader>

        <CardDetails>
            { false ? null : (<div>
                <h2>Welcome Drinks</h2>
                <p>We recommend taking an uber, lyft, or cab from your hotel to Corcoran's and back. You also can walk the bit more than 1.5 miles (35 minutes) and around Michigan Avenue and make some fun stops in the shops along on the way!</p>
            </div>) }

            <div>
                <h2>Ceremony + Reception</h2>
                <p>There is a parking lot included on site should you choose to drive your own vehicle to the ceremony.</p>
                <p>If you're staying at one of our hotel blocks (and should you desire to imbibe during the reception), we highly recommend Lyft, Uber, or a taxi to get to the ceremony. We have planned for a Shuttle to make two pick-ups later in the evening to get our guests back to the hotels safely. One pick up will likely occur around 10:00pm, and the second around 11:00pm at the end of the night.</p>
                <p>More detail to follow as we get closer to the big day!</p>
            </div>

            <div>
                <h2>Brunch</h2>
                <p>We'd recommend Lyft, Uber, or a taxi for our out-of-town guests not bringing their own vehicle. If you do plan on driving yourself, Logan Square has ample street parking (both paid and free). Check out the "Spot Angels" app to get live updates on free parking options, and "Spot Hero" if you'd prefer to pre-book and pay for parking instead.</p>

                <p>For our guests heading home on Sunday, the brunch venue (Park & Field) is just off the main highway leading to O'Hare airport. Even if you're heading home that afternoon, we'd love for you to take a quick detour and say hello on your way out! There will be space for you to hold your bags in the private dining area we've reserved.</p>
            </div>
        </CardDetails>
    </Card>
);