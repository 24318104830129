import styled from 'styled-components';

export const PersonHeader = styled.header`
    border-bottom: 1px solid var(--accent-color);
    column-gap: 1.5rem;
    display: grid;
    grid-column: 1;
    grid-row: 1;
    grid-template-columns: auto 1fr;
    
    & h1 {
        display: flex;
        margin-bottom: 0.5rem;
    }
`;
