import React from 'react';
import styled from 'styled-components';

import { CardContainer } from '../../Components/Cards/CardContainer';
import { Header } from '../../Components/Header';
import { Footer } from '../../Components/Footer';
import { SectionHeader } from '../../Components/SectionHeader';
import { Pills, Section } from 'src/Components/Pills';
import { PhotoCard } from './PhotoCard';
import { VenueCard } from './VenueCard';
import { CountdownCard } from './CountdownCard';
import { HotelCard } from './HotelCard';
import { DressCode } from './DressCode';
import { FAQCard } from './FAQCard';
import { TimelineCard } from './TimelineCard';
import { TransportationCard } from './TransportationCard';

const Main = styled.main`
    padding: 0 0 2rem 0;
`;

const sections = [
    new Section('when', "Wedding Weekend"),
    new Section('venue', "Venue"),
    new Section('travel', "Travel"),
    new Section('dresscode', "Dress Code"),
    new Section('faq', "FAQ")
];

export const Details = () => (
    <>
        <Header />

        <Main>
            <Pills sections={sections} />

            <CardContainer id="when">
                <SectionHeader>Wedding Weekend</SectionHeader>

                <CountdownCard />

                <TimelineCard />

                <PhotoCard />
            </CardContainer>

            <CardContainer id="venue">
                <SectionHeader>Venue</SectionHeader>
                
                <VenueCard />
            </CardContainer>
            
            <CardContainer id="travel">
                <SectionHeader>Travel</SectionHeader>

                <HotelCard />

                <TransportationCard />
            </CardContainer>

            <CardContainer id="dresscode">
                <SectionHeader>Dress Code</SectionHeader>
                
                <DressCode />
            </CardContainer>

            <CardContainer id="faq">
                <SectionHeader>FAQ</SectionHeader>
                
                <FAQCard />
            </CardContainer>
        </Main>

        <Footer />
    </>
);