import styled from 'styled-components';

import { device } from '../../Constants/MediaQueries';

export const BottomNav = styled.ul`
    align-items: stretch;
    background: var(--accent-color);
    bottom: 0;
    display: none;
    font-size: 0.8rem;
    grid-auto-flow: column;
    height: 4rem;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: fixed;
    width: 100vw;
    z-index: 1050;

    @media ${device.tablet} {
        display: grid;
    }
`;