import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { BottomNav } from './BottomNav';
import { TopNav } from './TopNav';

interface NavItemProps {
    isActive: boolean,
    target?: string,
    destination: string,
    children: React.ReactNode
}

interface NavLinkProps {
    isactive: string
}

const ListItem = styled.li`
    align-items: stretch;
    display: flex;
    letter-spacing: var(--uppercase-letter-spacing);
    text-transform: uppercase;
    margin: 0 0.8rem;

    &:first-child,
    &:last-child {
        flex: 1 1 0;
    }

    &:first-child {
        justify-content: flex-end;
    }

    &:last-child {
        justify-content: flex-start;
    }
`;

const NavLink = styled(Link)<NavLinkProps>`
    align-items: center;
    color: var(--accent-color);
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    justify-content: center;
    position: relative;
    text-decoration: none;
    transition: all .3s ease-in-out;

    &:visited {
        align-items: center;
        color: var(--accent-color);
        display: flex;
        flex-direction: column;
        font-size: 0.9rem;
        justify-content: center;
        position: relative;
        text-decoration: none;
        transition: all .3s ease-in-out;
    }

    &::after {
        background-color: var(--primary-color);
        content: '';
        height: 0.1rem;
        margin-top: 2px;
        opacity: 1;
        transform: scale(0);
        transform-origin: center;
        transition: opacity 300ms, transform 300ms;
        width: 100%;
        display: block;
    }

    &:focus::after,
    &:hover::after {
        transform: scale(0.8);
    }

    ${props => props.isactive === 'true' ? '&::after { transform: scale(0.8); }' : ''}

    ${BottomNav} & {
        color: var(--background-color);
    }

    ${BottomNav} ${ListItem}:hover &,
    ${BottomNav} &:hover,
    ${BottomNav} &:active,
    ${BottomNav} &:focus {
        color: var(--background-color);
        transform: scale(1.1);
        text-decoration: none;
    }

    ${BottomNav} &::after {
        background-color: var(--secondary-color);
        margin-top: 4px;
    }

    ${TopNav} &,
    ${TopNav} &:visited {
        opacity: 0.8;
    }

    ${TopNav} ${ListItem}:hover &,
    ${TopNav} &:hover,
    ${TopNav} &:active,
    ${TopNav} &:focus {
        color: var(--accent-color);
        opacity: 1;
        text-decoration: none;
     }
`;

// need title to stay when switching between top and bottom nav :(
export const NavItem = ({ isActive, target, destination, children } : NavItemProps) => (
    <ListItem>
        <NavLink to={destination} target={target} isactive={isActive?.toString()}>{children}</NavLink>
    </ListItem>
);