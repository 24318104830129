import styled from 'styled-components';

import { device } from '../../Constants/MediaQueries';


export const TopNav = styled.ul`
    align-items: stretch;
    background: none;
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    list-style-type: none;
    margin: 1rem 0;
    padding: 0;

    @media ${device.tablet} {
        display: none;
    }
`;