import styled from 'styled-components';

export const PillLink = styled.a`
    &,
    &:active,
    &:visited {
        align-items: center;
        background: transparent;
        color: var(--accent-color);
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 0.8rem;
        font-family: var(--header-font);
        letter-spacing: var(--uppercase-letter-spacing);
        padding: 0.5rem 1rem;
        text-decoration: none;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;
    }

    &:hover,
    &:focus {
        transform: scale(1.1);
        text-decoration: none;
    }

    &::after {
        background-color: var(--primary-color);
        content: '';
        display: block;
        height: 0.1rem;
        margin-top: 2px;
        transition: transform 300ms ease-in-out;
        opacity: 1;
        width: 100%;
    }

    &:hover::after,
    &:focus::after {
        transform: scale(0);
    }
`;