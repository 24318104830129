/* global anychart:false */
import React, { useEffect, useRef, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';
import { useFlags } from 'flagsmith/react';

import { Header } from '../../Components/Header';
import { Footer } from '../../Components/Footer';
import { device } from '../../Constants/MediaQueries';
import { Page } from '../../Constants/Page';
import { CardContainer } from '../../Components/Cards/CardContainer';
import { Card } from '../../Components/Cards/Card';
import MapData from './map.json';

declare var anychart: any;

const BubbleMap = styled.div`
    height: 500px;

    @media ${device.tablet} {
        height: 300px;
    }
`;

const CenterCard = styled(Card)`
    text-align: center;
`;

const CenterText = styled.div`
    margin-bottom: 1.5rem;
    text-align: center;
`;

export const RSVP = () => {
    const flags = useFlags(['rsvp']);

    const [librariesToLoad, setLibrariesToLoad] = useState([
        'https://cdn.anychart.com/releases/v8/js/anychart-base.min.js',
        'https://cdn.anychart.com/releases/v8/js/anychart-map.min.js',
        'https://cdn.anychart.com/releases/v8/js/anychart-data-adapter.min.js',
        'https://cdnjs.cloudflare.com/ajax/libs/proj4js/2.3.15/proj4.js',
        'https://cdn.anychart.com/releases/8.9.0/geodata/countries/united_states_of_america/united_states_of_america.js'
    ]);

    const scriptContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (librariesToLoad.length > 0) {
            const scriptTag = document.createElement('script');
            scriptTag.src = librariesToLoad[0];
            scriptTag.addEventListener('load', () => setLibrariesToLoad(librariesToLoad.slice(1)));
            document.body.appendChild(scriptTag);
        }
        else {
            anychart.licenseKey('bjdibbern-e7772de-fd10fd14');
            
            let data = [...MapData];

            data.forEach((d: any) => {
                d.fill = '#97653D';
                d.stroke = '#9C8A7C';
            });

            const dataSet = anychart.data.set(data).mapAs({ size: 'count' });

            // Creates map chart
            const map = anychart.map();

            // Define settings for maps regions
            map
                .unboundRegions()
                .enabled(true)
                .fill('#3a3f32')
                .stroke('#232919');

            // Set geodata using the script added
            map.geoData('anychart.maps.united_states_of_america');

            //Charting the bubbles
            map.bubble(dataSet);

            // Set bubble min/max size settings
            map.minBubbleSize('0.8%').maxBubbleSize('5%');

            map.background()
                .fill('transparent');

            // Set container id for the chart
            map.container('bubble-map');

            // Initiates chart drawing
            map.draw();
        }
    }, [librariesToLoad]);

    useEffect(() => {
        if (flags?.rsvp?.enabled && scriptContainer?.current?.innerHTML === '') {
            const scriptTag = document.createElement('script');
            scriptTag.src = 'https://cailingotdibbs.rsvpify.com/embed';
            scriptContainer.current!.appendChild(scriptTag);
        }
    }, [flags, scriptContainer]);

    return (
        <>
            <Header />

            <CardContainer>
                { flags?.rsvp?.enabled ? (
                    <CenterCard>
                        <CenterText>Have a question or an issue with the RSVP? Please check out our <HashLink to={`/${Page.Details}#faq`}>frequently asked questions here</HashLink>.</CenterText>
                        <div ref={scriptContainer}></div>
                    </CenterCard>
                ) : null }

                <CenterCard>
                    <span>{ !flags?.rsvp?.enabled ? 'See where everyone is coming from...' : 'We\'re so thankful so many of you are traveling in to visit us. Here\'s a map of where you all are coming from!' }</span>

                    <BubbleMap id="bubble-map"></BubbleMap>
                </CenterCard>
            </CardContainer>

            <Footer />
        </>
    );
}