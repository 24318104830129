import styled from 'styled-components';

export const DateLine = styled.p`
    align-items: center;
    display: flex;
    flex: 1;
    margin: 0 3rem;

    &:last-of-type {
        margin-bottom: 1rem;
    }
`;