import styled from 'styled-components';
import { device } from '../../Constants/MediaQueries';

interface PersonPhotoProps {
    showGooglyEyes?: boolean
}

export const PersonPhoto = styled.div<PersonPhotoProps>`
    display: grid;
    grid-column: 2;
    grid-row: span 2;
    justify-content: center;
    margin: 0 -20px 0 0;
    overflow: hidden;
    height: 280px;
    z-index: 10;

    & > * {
        height: 300px;
        margin-bottom: -20px;
    }

    & .eyes {
        display: ${props => props.showGooglyEyes ? 'inline !important' : 'none'};
    }

    & .glasses {
        display: ${props => props.showGooglyEyes ? 'none' : 'inline'};
    }

    @media ${device.laptop} {
        grid-column: 1;
        grid-row: 3;
        z-index: 0;
    }
`;
