import React, { useState } from 'react';
import styled from 'styled-components';

import faqItems from './faq.json';

interface FAQItemProps {
    item: typeof faqItems[0];
}

const ListItem = styled.li`
    & + & {
        margin-top: 0.5rem;
    }
`;

const Question = styled.div`
    color: var(--accent-color);
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
`;

export const FAQItem = ({ item } : FAQItemProps) => {
    const [showAnswer, setShowAnswer] = useState((window.visualViewport?.width || 0) >= 1280);

    const toggleAnswer = () => {
        setShowAnswer(curr => !curr);
    }

    return (
        <ListItem>
            <Question onClick={toggleAnswer}>{item.question}</Question>
            { showAnswer ? <div dangerouslySetInnerHTML={{ __html: item.answer }}></div> : null }
        </ListItem>
    );
}