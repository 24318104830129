import React from 'react';
import styled from 'styled-components';

const Header = styled.header`
    align-items: center;
    color: var(--primary-color);
    display: flex;
    flex-basis: 100%;
    margin: 0 0 2rem 0;
    max-width: 80vw;
    position: relative;
    width: 80vw;

    & h1 {
        font-family: 'British Shorthair', 'Courier New', Courier, monospace;
        font-size: 2rem;
        font-weight: bold;
    }

    & + & {
        margin-top: 2rem;
    }

    &::after,
    &::before {
        content: "";
        flex-grow: 1;
        background: var(--card-background-color);
        height: 1px;
        font-size: 0;
        line-height: 0;
        margin: 0 1.5rem;
    }
`;

interface PeopleHeaderProps {
    children: React.ReactNode,
    width?: string
}

export const SectionHeader = ({ children } : PeopleHeaderProps) => (
    <Header>
        <h1>{children}</h1>
    </Header>
);