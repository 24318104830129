import React from 'react';
import styled from 'styled-components';

import { ButtonLink } from '../../Components/ButtonLink';
import { Icon } from '../../Components/Icon';
import { GridCard } from './GridCard';
import { CardPhoto } from './CardPhoto';
import { device } from '../../Constants/MediaQueries';

import VenuePhoto from '../../img/ghl.jpg'

const VenueNavigation = styled.div`
    & * {
        margin-bottom: 0.5rem;
    }

    & p {
        margin-top: 0;
    }

    & h2 a {
        font-weight: normal;
    }

    @media ${device.laptop} {
        text-align: center;
    }
`;

export const VenueCard = () => (
    <GridCard>
        <CardPhoto>
            <img src={VenuePhoto} alt="Greenhouse Loft" />
        </CardPhoto>

        <VenueNavigation>
            <h1>Greenhouse Loft</h1>

            <h2>
                <Icon>📍</Icon>
                <a target="_blank" href="https://www.google.com/maps/place/Greenhouse+Loft/@41.931907,-87.69216,15z/data=!4m2!3m1!1s0x0:0xaf3364d8884012b1?sa=X&ved=2ahUKEwiEoMf9jpD7AhVvkmoFHWIyBCUQ_BJ6BAh3EAU">2545 W Diversey Ave, Chicago, IL 60647</a>
            </h2>

            <p>Parking is available and will be part of the RSVP</p>

            <p>As the night winds down, there will be two shuttles back to our hotels, details below</p>

            <br />
            
            <ButtonLink target="_blank" href="https://m.uber.com/ul/?action=setPickup&client_id=R-xt0O21bXspR381CjkUzR099lQckkik&pickup=my_location&dropoff[formatted_address]=Greenhouse%20Loft%2C%20West%20Diversey%20Avenue%2C%20Chicago%2C%20IL%2C%20USA&dropoff[latitude]=41.931907&dropoff[longitude]=-87.692160">Request an Uber</ButtonLink>
        </VenueNavigation>
    </GridCard>
);
