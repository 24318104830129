import React from 'react';
import styled from 'styled-components';

import { Card } from '../../Components/Cards/Card';
import { device } from '../../Constants/MediaQueries';

import cailin1 from '../../img/cj.avif';
import cailin2 from '../../img/cj.webp';
import cailin3 from '../../img/cj.jpg';
import couple1 from '../../img/transparent-couple2.avif';
import couple2 from '../../img/transparent-couple2.webp';
import couple3 from '../../img/transparent-couple2.png';
import william1 from '../../img/bj.avif';
import william2 from '../../img/bj.webp';
import william3 from '../../img/bj.jpg';

const UsCard = styled(Card)`
    align-items: center;
    column-gap: 1.5rem;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: initial;
    justify-content: center;
    width: 80vw;

    @media ${device.laptop} {
        row-gap: 1.5rem;
        grid-template-columns: initial;
        grid-template-rows: 1fr auto;
        width: 95vw;
    }
`;

const Story = styled.div`
    padding: 1.5rem;

    &::first-letter {
        color: var(--accent-color);
        float: left;
        font-family: 'British Shorthair', 'Courier New', Courier, monospace;
        font-weight: 600;
        font-size: 2rem;
        line-height: 2rem;
        padding: 0.5em 0.25em 0 0;
    }

    @media ${device.laptop} {
        grid-row: 1;
        padding: 1rem;
    }
`;

const StoryHeader = styled.header`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 3rem;
`;

const MatchHeader = styled.h1`
    color: var(--accent-color);
    font-size: 3rem;
    font-family: 'British Shorthair', 'Courier New', Courier, monospace;
    line-height: 150%;
`;

const StoryPhotos = styled.div`
    display: block;

    & img {
        border: 4px solid var(--accent-color);
        border-radius: 50%;
        display: inline-block;
        height: 100px;
        width: 100px;
    }

    & :nth-child(2) img {
        margin-left: -30px;
    }
`;

const UsHero = styled.img`
    grid-row: 2;
    margin: 0 0 -3rem -3rem;
    width: 30vw;

    @media ${device.laptop} {
        display: flex;
        margin: auto auto;
        padding-bottom: 1.5rem;
    }
`;

export const Us = () => (
    <UsCard>
        <picture>
            <source srcSet={couple1} type="image/avif" />
            <source srcSet={couple2} type="image/webp" />
            <UsHero src={couple3} alt="thumbnail of the couple" />
        </picture>

        <Story>
            <StoryHeader>
                <MatchHeader>It's a match!</MatchHeader>
                <StoryPhotos>
                    <picture>
                        <source srcSet={cailin1} type="image/avif" />
                        <source srcSet={cailin2} type="image/webp" />
                        <img src={cailin3} alt="thumbnail of the Cailin" />
                    </picture>
                    <picture>
                        <source srcSet={william1} type="image/avif" />
                        <source srcSet={william2} type="image/webp" />
                        <img src={william3} alt="thumbnail of the Will" />
                    </picture>
                </StoryPhotos>
                <h1>Cailin + William</h1>
            </StoryHeader>

            <p>Have you ever tried a banana margarita? What a first message. What a question. A question Cailin digested over several weeks as she sorted out why such an abomination even existed on a restaurant menu.</p>
            <p>Fast forward to January when Will reached out with a clever line about a "New Year's Resolution to meet up". Cailin saw the message, chuckled out loud, and again forgot to respond.</p>
            <p>After a few very delayed exchanges, the two finally met up for the aforementioned margaritas on one fateful February Friday. Cailin did not end up trying the yellow tequila slushy Will seemed to actually enjoy, but she did turn a fun shade of red when Will called her out for treating the meet-up like more of an interview than an actual date.</p>
            <p>A month and a half of "normal" dating led to a year+ dating in an active pandemic, and the rest is history. History that is backed up to iCloud if you're interested.</p>
        </Story>
    </UsCard>
);