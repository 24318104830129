import styled from 'styled-components';
import { device } from '../../Constants/MediaQueries';

export const AttributesList = styled.ul`
    align-items: center;
    display: flex;
    justify-content: stretch;
    list-style-type: none;
    margin: 0;
    overflow-x: auto;
    padding: 1rem 0;
    width: 100%;

    & li {
        align-items: center;
        color: var(--primary-color);
        display: inline-flex;
        flex-shrink: 0;
        flex-wrap: nowrap;
        font-size: 1rem;
        padding: 0 1.5rem;
    }

    & li + li {
        border-left: 1px solid var(--card-background-color);
    }

    @media ${device.laptop} {
        flex-wrap: wrap;
        z-index: 1;

        & li {
            display: flex;
            font-size: 1rem;
            padding: 0.2rem 1rem;
            width: 100%;
        }

        & li + li {
            border: none;
        }
    }
`;
