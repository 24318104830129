import React from 'react';
import styled from 'styled-components';

import { device } from '../Constants/MediaQueries';
import { Icon } from './Icon';

const FooterContainer = styled.footer`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 0;
    overflow: hidden;

    @media ${device.tablet} {
        margin-bottom: 4rem;
        margin-bottom: calc(4rem + env(safe-area-inset-bottom));
    }
`;

const Location = styled.div`
    border-bottom: 0.1rem solid var(--accent-color);
    line-height: 1em;
    font-family: var(--header-font);
    font-weight: 300;
    font-size: 2rem;
    letter-spacing: var(--uppercase-letter-spacing);
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    text-transform: uppercase;
`;

const Date = styled.div`
    align-items: center;
    display: flex;
    font-size: 2rem;
    font-weight: 300;
    justify-content: center;  
`;

const StarIcon = styled(Icon)`
    animation: dance 6s ease-in infinite;
    color: var(--accent-color);
    display: inline-block;
    font-size: 0.6em;
    margin: 0 0.25rem;
    vertical-align: middle;
    width: 1rem;  
`;

export const Footer = () => (
    <FooterContainer>
        <Location>Chicago</Location>
        <Date>11<StarIcon>&#10038;</StarIcon>04<StarIcon>&#10038;</StarIcon>23</Date>
    </FooterContainer>
);