import styled from 'styled-components';

export const PeopleContainer = styled.section`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem 2rem;
    justify-content: center;

    & + & {
        margin-top: 4rem;
    }
`;