import React from 'react';

import { AttributeIcon } from './AttributeIcon';
import { AttributesContainer } from './AttributesContainer';
import { AttributesList } from './AttributesList';
import { BridalPartyMember, Affiliation } from './BridalPartyMember';
import { PersonCard } from './PersonCard';
import { PersonHeader } from './PersonHeader';
import { PersonPhoto } from './PersonPhoto';
import { PromptContainer } from './PromptContainer';

import Meeko from '../../img/meeko.png';
import Groom from '../../img/groom.png';
import Dress from '../../img/dress.png';
import Paw from '../../img/cat-paw.png';
import Location from '../../img/location2.png';
import Handshake from '../../img/handshake.png';

interface PersonProps {
    person: BridalPartyMember
}

export const Person = ({ person: { photo: Photo, ...person } }: PersonProps) => {
    const showGooglyEyes = document.cookie.indexOf('goog=true') > -1;

    return (
        <PersonCard>
            <PersonHeader>
                <h1>{person.name}</h1>
            </PersonHeader>

            <PersonPhoto showGooglyEyes={showGooglyEyes}>
                {person.name === "Meeko" && !showGooglyEyes ? (
                    <img src={Meeko} alt={person.name} />
                ) : <Photo alt={person.name} />}
            </PersonPhoto>

            <PromptContainer>
                <div>{person.prompt}</div>
                <div>{person.response}</div>
            </PromptContainer>

            <AttributesContainer>
                <AttributesList>
                    <li>
                        <AttributeIcon>
                            <img src={person.affiliation === Affiliation.Groomsperson ?
                                Groom : person.affiliation === Affiliation.Bridesperson ? Dress : Paw} alt="Affiliation" />
                        </AttributeIcon>
                        {person.role ?? person.affiliation}
                    </li>
                    <li>
                        <AttributeIcon><img src={Location} alt="Location" /></AttributeIcon>
                        {person.location}
                    </li>
                    <li>
                        <AttributeIcon><img src={Handshake} alt="Handshake" /></AttributeIcon>
                        {person.context}
                    </li>
                </AttributesList>
            </AttributesContainer>
        </PersonCard>
    );
}