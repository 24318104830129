import React from 'react';
import { createRoot } from 'react-dom/client';
import { createGlobalStyle } from 'styled-components';

import { App } from './App';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: british shorthair;
    font-style: normal;
    font-weight: 400;
    src: local('British Shorthair'),
        url('/fonts/British-Shorthair.woff') format('woff'),
        url('/fonts/British-Shorthair.ttf') format('truetype'),
        url('/fonts/British-Shorthair.otf') format('opentype');
  }

  :root {
      --primary-color: #9C8A7C;
      --secondary-color: #080808;
      --background-color: #181C11;
      --card-background-color: #232919;
      --card-background-color-light: #3a3f32;
      --accent-color: #97653D;
      --header-color: #9C8A7C;
      --shadow-color: rgb(0 0 0 / 11%);
      --header-font: "Asar";
      --body-font: "Assistant";
      --uppercase-letter-spacing: 0.15em;
  }

  * { box-sizing: border-box }

  html, body {
      background: var(--background-color);
      box-sizing: border-box;
      color: var(--primary-color);
      font-family: "Voltaire",Georgia,serif;
      font-family: var(--body-font);
      line-height: 150%;
      margin: 0;
      padding: 0;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }

  h1, h2, h3, h4 {
    color: var(--header-color);
    font-family: var(--header-font);
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }

  h2 {
      font-size: 1.1rem;
      font-weight: 300;
      margin: 0;
      padding: 0;
  }

  h3 {
      font-size: 1rem;
      font-weight: bold;
      margin: 0;
      padding: 0;
  }

  a,
  a:active,
  a:visited {
      color: var(--accent-color);
      text-decoration: none;
  }

  a:focus,
  a:hover {
      text-decoration: underline;
      text-decoration-color: var(--accent-color);
      text-decoration-thickness: 1px;
  }

  p {
      margin: 1rem 0;
  }

  p:last-child {
      margin-bottom: 0;
  }

  main {
    padding: 0 0 2rem 0;
  }

  footer {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 2rem 0;
      overflow: hidden;
  }

  @keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    } 
  }

  @keyframes rotate {
      0% {
          transform: rotate(0deg);
      }
      50% {
          transform: rotate(5deg) scale(1.1);
      }
      100% {
          transform: rotate(0deg);
      }
  }

  @keyframes dance {
      0% {
          transform: rotate(0);
      }
      25% {
          transform: rotate(-10deg);
      }
      75% {
          transform: rotate(10deg);
      }
      100% {
          transform: rotate(0);
      }
  }

  @keyframes fade-in {
      0% {
          opacity: 0;
      }
      100% {
          opacity: 1;
      }
  }
`;

const domNode = document.getElementById('root')!;
const root = createRoot(domNode);

root.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>
);