import React from 'react';

export enum Affiliation {
    Bridesperson = "Bridesperson",
    Groomsperson = "Groomsperson",
    Us = "Family"
}

export enum Role {
    BestMan = "Best Man",
    ManOfHonor = "Man of Honor",
    MaidOfHonor = "Maid of Honor"
}

export interface BridalPartyMember {
    name: string;
    photo: React.ComponentType<any>;
    prompt: string;
    response: string;
    affiliation: Affiliation;
    role?: Role;
    location: string;
    context: string;
}
