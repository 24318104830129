import { ReactComponent as Alison } from './Alison.svg';
import { ReactComponent as Brandi } from './Brandi.svg';
import { ReactComponent as Carl } from './Carl.svg';
import { ReactComponent as Chris } from './Chris.svg';
import { ReactComponent as Gillian } from './Gillian.svg';
import { ReactComponent as Jerry } from './Jerry.svg';
import { ReactComponent as Kevin } from './Kevin.svg';
import { ReactComponent as Meeko } from './Meeko.svg';
import { ReactComponent as Michael } from './Michael.svg';
import { ReactComponent as Michelle } from './Michelle.svg';
import { ReactComponent as Monika } from './Monika.svg';
import { ReactComponent as Nealand } from './Nealand.svg';
import { ReactComponent as Sally } from './Sally.svg';
import { ReactComponent as Scotty } from './Scotty.svg';
import { ReactComponent as Sharon } from './Sharon.svg';

export default {
    Alison,
    Brandi,
    Carl,
    Chris,
    Gillian,
    Jerry,
    Kevin,
    Meeko,
    Michael,
    Michelle,
    Monika,
    Nealand,
    Sally,
    Scotty,
    Sharon
};
