import styled from 'styled-components';

import { HomeForm } from './HomeForm';

export const HomeInput = styled.input`
    border: none;
    border-radius: 0;
    -webkit-appearance: none;
    margin: 0;
    font-family: "Voltaire",Georgia,serif;
    font-family: "Asar";
    font-size: 1rem;
    transition: all 1s ease-in-out;

    &[type="submit"] {
        background: var(--card-background-color);
        border: none;
        color: var(--header-color);
        flex: 1 1 0;
        height: 2rem;
        letter-spacing: var(--uppercase-letter-spacing);
        text-align: center;
        text-transform: uppercase;
        z-index: 1011;

        &:hover {
            background: transparent;
            background-size: 400% 400%;
            color: var(--background-color);
        }
    }

    ${HomeForm}:hover &[type="submit"] {
        width: auto;
    }

    &[type="password"] {
        border-right: 1px solid var(--card-background-color);
        flex: 0;
        height: 2rem;
        padding: 1px 0;
        position: relative;
        width: 0;
    }

    ${HomeForm}:hover &[type="password"],
    &[type="password"]:focus,
    &[type="password"]:active {
        border-right: 1px solid var(--card-background-color);
        flex: 2 1 0;
        padding: 1px 6px 1px 2.5rem;
    }
`;