import styled from 'styled-components';

export const CardContainer = styled.section`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & + & {
        margin-top: 4rem;
    }

    & > * + * {
        margin-top: 2rem;
    }
`;