import React from 'react';
import styled from 'styled-components';

import { PillContainer } from './PillContainer';
import { PillLink } from './PillLink';
import { device } from '../../Constants/MediaQueries';

export class Section {
    id: string;
    displayName: string;

    constructor(id: string, displayName: string) {
        this.id = id;
        this.displayName = displayName;
    }
}

interface PillsProps {
    sections: Section[]
}

const PillList = styled.ul`
    align-items: center;
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style-type: none;
    max-width: 40vw;
    margin: 0;
    padding: 0;
    
    @media ${device.tablet} {
        max-width: 80vw;
    }
`;

const PillListItem = styled.li`
    padding: 0.5rem;
`;

export const Pills = ({ sections } : PillsProps) => (
    <PillContainer>
        <PillList>
            {
                sections.map(({ id, displayName }) => (
                    <PillListItem key={id}>
                        <PillLink href={`#${id}`}>{displayName}</PillLink>
                    </PillListItem>
                ))
            }
        </PillList>
    </PillContainer>
);